////////////////       Implémentation "Media Query + Mixin"        ////////////////

$breakpoints: (
    "XS": 510px,
    "S": 768px,
    "M": 900px,
    "L": 1024px,
    "XL": 1280px,
    "XXL": 1440px
);

/* from... */
@mixin media-min($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) {
      &{ @content; }
  }
}

/* to... */
@mixin media-max($_key) {
  @media screen and (max-width: (map-get($breakpoints, $_key) - 1)) {
      &{ @content; }
  }
}

/* from... to... */
@mixin media-between($_keymin, $_keymax) {
  @media screen and (min-width: map-get($breakpoints, $_keymin)) and (max-width: (map-get($breakpoints, $_keymax) - 1)) {
      &{ @content; }
  }
}

/* at... */
@mixin media($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) and (max-width: (map-get($breakpoints, nth(map-keys($breakpoints), index(map-keys($breakpoints), $_key) + 1)) - 1)) {
      &{ @content; }
  }
}

//////////////// Example of use ////////////////

// @mixin media-min($_min-width) {
//   @media screen and (min-width: $_min-width) {
//       &{ @content; }
//   }
// }

////  Try example  ////

// @include media-min("L") {
//   /* from 1024px to infinite */
// }
// @include media-max("M") {
//   /* from 0px to 899px */
// }
// @include media-between("S", "XL") {
//   /* from 768px to 1279px */
// }
// @include media("M") {
//   /* from 900px to 1023px */
// }


////  Media Queries  ////

// @include media-max("S") {
//   /* from 0px to 767px */
// }

// @include media-between("S", "XL") {
//   /* from 768px to 1279px */
// }

// @include media-min("XL") {
//   /* from 1280px to infinite */
// }


    //// For Height ////
// @media screen and (max-height: 599px) {
//   .smthg {
//     font-size: 2.4rem;
//   }
// }

@media screen and (min-width: 688px) and (max-width: 991){

  .formContainer {
    margin: 20px;

    .form {
      img {
        margin-bottom: 11vh;
      }
    }
  }

}

@media screen and (min-width: 992px) and (max-width: 1311) {

  .formContainer {
    margin: 20px;
  }
}

@media screen and (min-width: 1312px){

  .formContainer {
    padding: 20px;
  }

}

@media screen and (min-width: 768px) and (orientation: portrait) {

    body {

        .formContainer {

            padding: 4.25rem !important;
          
            .left-area {
                display: none;
            }   
    
        }
    }
    
}

/////////////////// Aymen Old code  /////////////////////

// @media screen and (max-width: 768px) {
//   .form {
//     width: 100% !important;
//     height: 100vh !important;
//   }
//   .formContainer {
//     padding: 0;

//     .left-area {
//       display: none;
//     }
//   }

// }

// @media screen and (max-width: 510px) {
//   .offreDesc {

//     // h2 {
//     //   font-size: 24px;
//     // }

//     img {
//       width: 30px !important;
//     }
//   }
// }

body {
    background-color: #34393e;

    .formContainer {
        background-color: #34393e;
        display: flex;
        padding: 1.25rem; // A mettre uniquement sur les largeurs supérieures à 700px (a test valeur au pif)
        // margin: 20px; /* Remettre padding si cassure  <- Si grand margin si petit padding */
        height: 100vh;
        min-height: 600px;
      
        // @include media-max("S") {
        //   padding: 0;
        // }
        
        .left-area {
            float: left;
            text-align: center;
            flex: 1;
            align-self: center;
        
            @include media-max("S") {
                display: none;
            }

            img {
                width: 85%;
            }

        }
      
        // Toutes les images à 85% ???
        // img {
        //     width: 85%;
        // }
      
        .slider__frame {
        // border-radius: .16667em;
        // text-align: center;
        // height: 100%;
        // line-height: 150px;
        // background: transparent;
        // font-size: 1em;
        // font-weight: 900
        }
      
        .form {
            
            // overflow: hidden;
            // // position: relative;
            // padding: 20px 30px;     // A modifier en media query
            // top: 0;
            // bottom: 0;
            // left: 0;
            // right: 0;
            // margin: auto;    // A modifier en media query
            height: 100%;
            // width: 40%;

            background-color: #34393e;
            display: flex;
            flex-direction: column;
            flex: 1;
            @include media-min("L") {     // To check if L ok
                align-items: center;
            }
            
            
            // Casse le design sur petit écran
            // @include media-max("S") {
            //     width: 100% !important;
            //     height: 100vh !important;
            // }
    
            img {
                width: 75%;
                // margin-bottom: 6vh;
            }
    
            span {
                color: #e62375;
            }
    
            h2 {
                color: white;
                display: inline;
                margin-left: 8px;
                vertical-align: middle;
            }
    
            h5 {
                color: white;
            }
    
            input {
                border-radius: 14px;
                color: white;
                background-color: black;
                font-weight: 600;
                border: none;
                font-size: 16px;
                outline: none;
                height: 50px;
                margin: auto;
                padding: 4px 12px;
                padding-top: 10px;
                width: 100%;
    
    
                &::placeholder {
                    color: white;
                }
            }
    
            input[type=password] {
                color: gray;
            }
            input[type=text],input[type=password],input[type=email],input[type=date] {
                -webkit-appearance: none;
                -webkit-min-logical-width: calc(100% - 16px);
                -moz-appearance: none;
            }

            #GodFatherCheckbox {
                margin-bottom: 0.5em;
            }

            #CguCheckbox {
                margin-top: 0.5em;
                margin-bottom: 0.5em;
            }


            #content {

                display: flex;
                flex-direction: column;
                // height: 100%;    // Fait cassé le design sur les form > 3
                flex-grow: 1;

                // Removing Overflow of next form
                // min-height: 0;
                overflow: hidden;

                @include media-min("L") {     // To check if L ok
                    width: 75%;
                }

                
                #main {

                    display: flex;
                    flex-direction: column;
                    // To Check if it does not break Forms
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    flex-grow: 1;

                    // Removing Overflow of next form
                    min-height: 0;
                    .overflowFlexHidden {
                        min-height: 0; 
                    }

                    .forms {
                        // flex: 1;
                    }

                    .forms ~ .forms {
                        // display: none;
                    }

                    .formOne {

                        #formOneName {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: 1.5em;
                        }

                        form {

                            .inputContainer {
                                position: relative;
                                width: 100%;
                                z-index: 2;

                                // .label {
                                //     color: rgb(170, 169, 169);
                                //     font-size: 10px;
                                //     position: absolute;
                                //     top: 4px;
                                //     left: 12px;
                                // }
                                  
                                // .label-champ {
                                //     color: #969292;
                                //     margin-top: 8px;
                                //     margin-left: 12px;
                                //     font-size: 14px;
                                  
                                // }

                                // .icon {
                                //     position: absolute;
                                //     color: #e62476;
                                //     right: 16px;
                                //     top: 14px;
                                //   }

                            }

                            
                            
                        }

                    }

                    .formTwo {

                        #formTwoName {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: 1.5em;
                        }

                    }

                    .formThree {

                        #formThreeName {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: 1.5em;
                        }

                        #genderSelect {
                            border-radius: 14px;
                            color: white;
                            background-color: black;
                            font-weight: 600;
                            border: none;
                            font-size: 16px;
                            outline: none;
                            height: 50px;
                            margin: auto;
                            padding: 4px 12px;
                            padding-top: 10px;
                            width: 100%;
                        }

                    }

                    .formFour {

                        #formFourName {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: 0.5em;
                        }
                    }

                    .formFive {

                        // To detect in FormJS Switch error
                        // display: flex;
                        // flex-direction: column;

                        #formFiveName {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: 0.5em;
                        }

                        // #form_five > form:nth-child(2) > div:nth-child(1) {
                        //     display: flex;
                        //     flex-direction: column;
                        //     justify-content: space-evenly;
                        //     flex-grow: 1;
                        // }
                    }

                }

                // A modifier          
                .footer {
                    // padding: 10px 0;
                    // position: absolute;
                    display: flex;
                    // bottom: 0;
                    // right: 0;
                    // left: 0;
                    // margin: auto;
                    // width: 90%;
                    align-items: center;
                    z-index: 1;

                    padding-bottom: 1vh;        //Que sur High height écran

                    #taskBar {

                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .footerText {
                            color: white;
                            position: absolute; // A check
                            display: flex;
                            // bottom: 30px;
                            // left: 20px;
                            // margin: auto;
                            font-size: 10px;
                            width: max-content;
                        }
                      
                        .answered {
                            flex: 1;
                            display: flex;
                            margin-right: 10px;
                            background-color: black;
                            border-radius: 21px;
                            margin-top: 20px;
                        
                            // @include media-min("XXL") {
                            //     margin-top: 80px;
                            // }
                        
                            div {
                        
                            width: 25%;
                            height: 6px;
                            background-color: black;
                            border-radius: 100px;
                            }
                        
                            .firstStep {
                                background-color: #e62375;
                            }
                        }
                    }        
                  
                    .arrow {        // A modifier pour responsive
                        cursor: pointer;
                        position: relative;
                        border-radius: 50%;
                        height: 35px;
                        padding: 10px;
                        width: 35px;
                        color: #fff;
                        text-align: center;
                        font-size: 25px;
                        background: linear-gradient(90deg, #e73a88, #e4075f);
                  
                        // @include media-min("XXL") {
                        //     margin-top: 70px;
                        // }
                    }
                  
                    .ok {
                        position: absolute;
                        font-weight: 200;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

            }

        }
      
        .autocomplete-dropdown-container{
          padding: 10px;
        }
    }

  
}


.autocomplete-dropdown-container div{
  padding: 2px 5px;
}
.autocomplete-dropdown-container div:hover{
  background-color: #1e1f23!important;
}

// Utilisé au dessus
// .footerText {
//   color: white;
//   position: absolute;
//   display: flex;
//   bottom: 30px;
//   left: 20px;
//   margin: auto;
//   font-size: 10px;
//   width: max-content;
// }

.empty-space{
  min-height: 0px;
}

// Utilisé au dessus
// .footer {
//   padding: 10px 0;
//   position: absolute;
//   display: flex;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   margin: auto;
//   width: 90%;
//   align-items: center;
//   z-index: 1;

//   .answered {
//     flex: 1;
//     display: flex;
//     margin-right: 10px;
//     background-color: black;
//     border-radius: 21px;
//     margin-top: 20px;

//     @include media-min("XXL") {
//       margin-top: 80px;
//     }

//     div {

//       width: 25%;
//       height: 6px;
//       background-color: black;
//       border-radius: 100px;
//     }

//     .firstStep {
//       background-color: #e62375;
//     }
//   }



// Utilisé au dessus
//   .arrow {
//     cursor: pointer;
//     position: relative;
//     border-radius: 50%;
//     height: 35px;
//     padding: 10px;
//     width: 35px;
//     color: #fff;
//     text-align: center;
//     font-size: 25px;
//     background: linear-gradient(90deg, #e73a88, #e4075f);

//     @include media-min("XXL") {
//       margin-top: 70px;
//     }
//   }

// Utilisé au dessus
//   .ok {
//     position: absolute;
//     font-weight: 200;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     margin: auto;
//   }
// }

// Utilisé ???
.firstLine {
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
  justify-content: center;
}

.inputContainer {
  position: relative;
  width: 100%;
  z-index: 2;
}

// Utilisé ???
.classiqueOffre,
.TalentOffre {

  border-radius: 5px;
  color: #e62375;
  background-color: black;
  font-weight: 600;
  border: none;
  font-size: 16px;

  margin: auto;
  padding: 15px 12px;
  position: relative;
  width: 81%;
  background-color: white;
  margin-top: 10px;

  .iconOffre {
    color: grey;
  }

  .text {
    text-align: center;

  }
}

.label {
  color: rgb(170, 169, 169);
  font-size: 10px;
  position: absolute;
  top: 4px;
  left: 12px;
}

.icon {
  position: absolute;
  color: #e62476;
  right: 16px;
  top: 14px;
}

.label-champ {
  color: #969292;
  margin-top: 8px;
  margin-left: 12px;
  font-size: 14px;

}

.hideButton {
  display: none;
}

.hide {
    display: none !important;
}

.error {
  padding-bottom: 1.5em;
  margin-top: 8px;
  margin-left: 12px;
  color: red;
  font-size: 14px;
}

.region {
  display: none;
  justify-content: start;
  align-items: center;
  border-radius: 0px 0px 14px 14px;
  color: white;
  background-color: black;
  font-weight: 600;
  border: none;
  font-size: 16px;
  height: 50px;
  margin: auto;
  padding: 4px 12px;

  width: 100%;

  * {
    margin-right: 4px;
    color: #b3b1b1;
  }
}


.offreDesc {        // To Check
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  padding: 15px;
  margin: auto;
  background-color: #1e1f23;
  text-align: left;
  border-radius: 4vh;
  height: 100%;

  p {

    color: white;
    font-size: 13px;
    margin-top: 1px;
    opacity: 0.5;
  }

  .title {
    opacity: 1;
    font-size: 11px;
  }

  .main-title{
    font-size: 32px;
    color: #fff;
  }

  img {
    margin-bottom: 0 !important;
    width: 40px !important;
    vertical-align: middle!important;
    margin-left: 10px;

    @include media-min("XS") {
      width: 30px !important;
    }

  }

  h1{
    color: #fff;
    font-weight: 100;
    font-size: 56px;
    margin-bottom: 20px;
  }

  h2 {
    color: #fff;
    font-weight: 100;
    font-size: 1.6em;
    // margin-bottom: 20px;
    display: block !important;
    margin-left: 0 !important;
  }

}
.toChooseButton{
  cursor: pointer;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  font-size: 16px;
  min-height: 33px; // virer min- si pb
//   margin-top: ;
  margin: auto;
  padding: 7px 12px;
  position: relative;
  width: 60%;
  background-color: #e62375;
  color: white;
  z-index: 10;
}
.sendButton {
  cursor: pointer;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  font-size: 16px;
  min-height: 33px; // virer min- si pb
//   margin-top: ;
  margin: auto;
  padding: 7px 12px;
  position: relative;
  width: 80%;
  //background-color: #e62375;
  background-color: black;
  color: white;
  z-index: 10;

}

.stripeBtn {
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  border: none;
  font-size: 16px;
  min-height: 33px; // virer min- si pb
//   margin-top: ;
  margin: auto;
  padding: 7px 12px;
  position: relative;
  width: 100%;
  background-color: #e62375;
  color: white;
  z-index: 10;
  height: 40px;
  margin-bottom: 10px;

}
.popup-box{
  backdrop-filter: blur(5px);
}
.box{
  padding: 10px;
  width: 100%;
  
}

.confirmButton {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 20%;
}

.sendButtonGrey {
  cursor: pointer;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  font-size: 16px;
  min-height: 33px; // virer min- si pb
  margin: auto;
  padding: 4px 12px;
  position: relative;
  min-width: 60%; // virer min- si pb
  background-color: #a5a3a4;
  color: white;
}

// .sendButtonb {
//   cursor: pointer;
//   border-radius: 100px;
//   font-weight: 600;
//   border: none;
//   font-size: 16px;

//   margin: auto;
//   padding: 7px 18px 7px 14px;
//   position: relative;
//   width: 0.1%;
//   background-color: #e62375;
//   color: white;

// }

// .sendButtonc {

//   cursor: pointer;
//   border-radius: 100px;
//   font-weight: 600;
//   border: none;
//   font-size: 16px;
//   margin: auto;
//   padding: 7px 12px 7px 13px;
//   position: relative;

//   background-color: white;
//   color: #000;


// }

// .sendButtonx {
//   cursor: pointer;
//   border-radius: 100px;
//   font-weight: 600;
//   border: none;
//   font-size: 16px;
//   height: 10px;
//   margin: auto;
//   padding: 0px 5px;
//   position: relative;
//   width: 0.1%;
//   background-color: white;
//   color: white;
//   margin-top: 20px;
//   margin-left: 100px;

// }

// #scrolmain2 {
//   display: none;
// }

// .sendButtony {

//   cursor: pointer;
//   border-radius: 100px;
//   font-weight: 600;
//   border: none;
//   font-size: 16px;
//   height: 10px;
//   margin: auto;
//   padding: 0px 5px;
//   position: relative;
//   width: 0.1%;
//   background-color: #e62375;
//   color: white;
//   margin-top: 20px;
//   margin-left: 10px;

// }

.scroll table {     // For Caroussel Form -> ToCheck
  width: 100%;
  margin-top: 20px;
  padding: 50px;
}

.scroll table td.text-right {       // For Caroussel Form -> ToCheck
  text-align: right
}

.offreDesc h4 {
  margin-left: 0 !important;
  text-align: center;
  font-size: 18px;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}

.scroll .carousel-slider {      // For Caroussel Form -> ToCheck
  padding-top: 26px;
  .control-dots {

    top: -13px;
    z-index: 0;

    .dot{
      background-color: #fff;
      opacity: 1;
    }

    .selected{
      background-color: #e62375;
    }

  }
}

.centred {
  text-align: center;
}

.fadeOut {
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity:1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
    to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}

.spinner-border {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border: 0.2rem solid #e62375;
    border-right-width: 2px;
    border-right-color: transparent;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
    animation: 1s linear infinite spinner-border;
    display: flex;
    justify-content: center;
}

.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
